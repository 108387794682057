










































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  applyActionCode,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator";

@Component
export default class Auth extends Mixins(FormValidator) {
  private routeQuery = new URLSearchParams(window.location.search);
  private mode = this.routeQuery.get("mode");
  private oobCode = this.routeQuery.get("oobCode")
    ? this.routeQuery.get("oobCode")
    : "";
  private continueUrl = this.routeQuery.get("continueUrl");
  private lang = this.routeQuery.get("lang");
  private apiKey = this.routeQuery.get("apiKey");

  public formData = {
    password: "",
    password_confirmation: "",
  };
  private showPassword = false;
  private showConfirmPassword = false;

  // Configuración Firebase
  private firebaseConfig: any = null;
  private app: any = null;
  private auth: any = null;
  private title = "Cargando...";
  private status = "loading";
  private errorMessage = "";
  private isFirebaseInitialized = false;

  private async beforeMount() {
    try {
      this.firebaseConfig = { apiKey: this.apiKey };
      this.app = initializeApp(this.firebaseConfig);
      this.auth = getAuth(this.app);
      this.isFirebaseInitialized = true;
    } catch (error) {
      console.error(error);
      if (error.code === "auth/invalid-api-key") {
        this.errorMessage =
          "Error en la configuración del sistema. Contacte al Municipio.";
      } else if (error.code === "auth/invalid-action-code") {
        this.errorMessage =
          "El enlace ya no es válido. Por favor, solicita un nuevo enlace para continuar.";
      } else if (error.code === "auth/expired-action-code") {
        this.errorMessage =
          "El enlace ha caducado. Por favor, solicita un nuevo enlace para continuar.";
      } else {
        this.errorMessage =
          "Ocurrió un error: " + (error.message || "Acción inválida.");
      }
      this.status = "error";
    }
  }
  private async mounted() {
    if (!this.mode) {
      this.$router.push("/");
      return;
    }
    // muestra según el `mode`
    try {
      if (this.mode === "resetPassword" && this.oobCode) {
        this.title = "Reestablecer Contraseña";
        await verifyPasswordResetCode(this.auth, this.oobCode); // Verifica el código
        this.status = "passwordReset";
      } else if (this.mode === "verifyEmail" && this.oobCode) {
        this.title = "Verificación de Cuenta";
        await applyActionCode(this.auth, this.oobCode); // Aplica el código para verificar el correo
        this.status = "verified";
      } else {
        throw new Error("Modo no soportado o datos faltantes.");
      }
    } catch (error) {
      console.error(error);
      if (error.code === "auth/invalid-api-key") {
        this.errorMessage =
          "Error en la configuración del sistema. Contacte al Municipio.";
      } else if (error.code === "auth/invalid-action-code") {
        this.errorMessage =
          "El enlace ya no es válido. Por favor, solicita un nuevo enlace para continuar.";
      } else if (error.code === "auth/expired-action-code") {
        this.errorMessage =
          "El enlace ha caducado. Por favor, solicita un nuevo enlace para continuar.";
      } else {
        this.errorMessage =
          "Ocurrió un error: " + (error.message || "Acción inválida.");
      }
      this.status = "error";
    }
  }

  private validatePassword() {
    if (
      this.formData.password.length == 0 ||
      this.formData.password_confirmation.length == 0
    ) {
      this.$swal({
        icon: "warning",
        title: "Reestablecer contraseña",
        text: "Las contraseñas no puedes estar vacías",
        confirmButtonText: "Aceptar",
      });
      return false;
    }
    if (this.formData.password.length <= 5) {
      this.$swal({
        icon: "warning",
        title: "Reestablecer contraseña",
        text: "La contraseña debe tener al menos 6 caracteres.",
        confirmButtonText: "Aceptar",
      });
      return false;
    }
    if (this.formData.password !== this.formData.password_confirmation) {
      this.$swal({
        icon: "warning",
        title: "Reestablecer contraseña",
        text: "Las contraseñas no coinciden",
        confirmButtonText: "Aceptar",
      });
      return false;
    }
    return true;
  }

  private togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  private toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  private handleRedirectOrOpenApp() {
    if (this.continueUrl) {
      window.location.href = this.continueUrl;
    } else {
      this.$router.push("/");
    }
  }

  private async handlePasswordReset() {
    if (!this.validatePassword()) return;
    try {
      await confirmPasswordReset(
        this.auth,
        this.oobCode as string,
        this.formData.password
      );
      this.status = "verified";
      this.title = "Reestablecer Contraseña";
    } catch (error) {
      console.error(error);
      this.status = "error";
      this.errorMessage =
        "No se pudo cambiar la contraseña. Inténtelo más tarde.";
    }
  }
}
