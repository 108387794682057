






import { Component, Vue } from "vue-property-decorator";
import RecoverFirebase from "@/components/auth/RecoverFirebase.vue";

@Component({
  components: {
      RecoverFirebase
  }
})
export default class RecoverFirebaseView extends Vue {}
